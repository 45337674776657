<template>
    <div class="mt-5">
        <v-layout justify-center>
            <v-flex xs12 sm10 md8 lg8>
                <div class="text-xs-center">
                    <div class="outlayer">
                        <div class="size">
                            <img src="@/assets/JKOPay_ICON.png" />
                        </div>
                    </div>
                    <p class="subheading mt-3">{{ $t('jkopayWaitingPage.pay-by-jko') }}<br>{{ $t('jkopayWaitingPage.pay-within-20mins') }}</p>
                    <v-btn small outline color="primary" class="body-2" @click="directToJKOPay">{{ $t('jkopayWaitingPage.continue') }}</v-btn>
                </div>
            </v-flex>
        </v-layout>
        <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err"></waiting-dialog>
        <message-dialog
            :show.sync="msgDialog.show"
            :msg="msgDialog.msg"
            :primary-btn="msgDialog.primaryBtn"
            @primary="closeMsgDialogFunc"
        ></message-dialog>
    </div>
</template>

<script>
import store from '@/store'
import axios from 'axios'
import { SideBtnType, HostUrl } from '@/store'
import { getAxiosConfig } from '@/utils/AuthService'
import { HOMETYPES } from '@/utils/utils'
import WaitingDialog from '@/components/WaitingDialog.vue'
import WaitDialogControl from '@/mixins/WaitDialogControl.vue'
import MessageDialog from '@/components/MessageDialog'
import MessageDialogControl from '@/mixins/MessageDialogControl'
import i18n from '@/i18n'

export default {
    name: 'jkopay-wait-page',
    components: { MessageDialog, WaitingDialog },
    mixins: [ MessageDialogControl, WaitDialogControl ],
    data() {
        return {
            payload: null,
            boxId: '',
            sendTimes: 0,
            reloadPage: false,
            closeMsgDialogFunc: () => {}
        }
    },
    methods: {
        directToJKOPay() {
            let url = ""
            this.showDialog(i18n.t('jkopayWaitingPage.trading'), i18n.t('jkopayWaitingPage.transaction-failed'), 60000)
            if (store.getters.jkoPayParameter.from === 'putin') {
                url = HostUrl + `/api/box/${this.boxId}/pre_putin`
                this.payload.send_times = this.sendTimes
                this.payload.browser = navigator.userAgent
            } else {
                url = `${HostUrl}/api/box/${this.boxId}/takeout/pre_pay`
            }
            let payload = this.payload
            let self = this
            axios.post(url, payload, getAxiosConfig())
                .then(function(response) {
                    if (response.data.code === 0) {
                        self.sendTimes++
                        window.location.href = response.data.payment_url.web
                    } else if (response.data.code === 508) {
                        self.closeMsgDialogFunc = self.goToHomePage
                        if (response.data.message === '付款完成') response.data.message = i18n.t('putIn.payment-completed')
                        self.closeDialog()
                        self.showMsgDialog(response.data.message, i18n.t('jkopayWaitingPage.confirm'))
                    } else {
                        if (response.data.message.includes('重新操作')){
                            self.closeMsgDialogFunc = self.goToHomePage
                            self.closeDialog()
                            response.data.message = i18n.t('putIn.payment-wrong-input')
                            self.showMsgDialog(response.data.message, i18n.t('jkopayWaitingPage.confirm'))
                        } else if (response.data.message.includes('[置物完成]')) {
                            self.closeMsgDialogFunc = self.goToHomePage
                            self.reloadPage = true
                            self.closeDialog()
                            self.showMsgDialog(response.data.message, i18n.t('jkopayWaitingPage.confirm'))
                        } else {
                            self.closeDialog()
                            store.commit('setSnackBar', { message: response.data.message, color: 'error' })
                        }
                    }
                })
                .catch(function() {
                    self.closeDialog()
                    store.commit('setSnackBar', { message: i18n.t('jkopayWaitingPage.transaction-failed'), color: 'error' })
                })
        },
        goToHomePage() {
            if (store.getters.pageHome === HOMETYPES.STORE | location.href.indexOf('storehost') !== -1)
                this.$router.push(`/${i18n.locale}/storehome`)
            else if (store.getters.pageHome === HOMETYPES.WASH | location.href.indexOf('washhost') !== -1)
                this.$router.push(`/${i18n.locale}/washhome`)
            else
                this.$router.push(`/${i18n.locale}`)
            if (this.reloadPage) {
                setTimeout(location.reload(), 1000)
            }
        },
        init() {
            this.payload = null
            this.boxId = ''
            this.sendTimes = 0
            this.reloadPage = false
            store.commit('setJkoPayParameter', null)
        }
    },
    activated() {
        if (!store.getters.jkoPayParameter) {
            this.goToHomePage()
            return
        }
        this.payload = store.getters.jkoPayParameter.payload
        this.boxId = store.getters.jkoPayParameter.box_id
        store.commit('setTitle', i18n.t('jkopayWaitingPage.jkopay'))
        store.commit('setSideBtnType', SideBtnType.Back)
    },
    deactivated() {
        this.init()
    }
}
</script>

<style scoped>
.outlayer {
    display: flex;
    justify-content: center;
}

.size {
    height: 200px;
    width: 200px;
}

.size img {
    height: 200px;
    width: 200px;
}
</style>